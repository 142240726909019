import React, { Fragment, useEffect, useState } from 'react';
import { Col, Button, Row } from "reactstrap";
import { DateForm, InputForm, SelectForm, TableWithVirtualizedRow } from '../Basicos';
import { formatLaravelDate, parseDate } from '../Basicos/funcoes';
import { axiosApi } from '../Basicos/axiosInstances';
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import CardHoc from '../Hoc/cardHoc';
import FileServer from 'file-saver';
import { AiFillFileExcel, AiFillFilePdf } from 'react-icons/ai';
import { CardCalendarioPadrao } from './Cards';

import { transformaDataEmString, dataDeUmaSemanaEmString, semanaInicialDoPeriodo, avancarMes, retrocederMes, avancarSemana, retrocederSemana, diaDaSemanaDaDataString, semanaInicialDoPeriodoData } from '../../services/calendarioOferta';
import { ModalVisualizar } from '../Modal';
import ModalConteudo from '../Modal/ModalConteudo';
import PDFSemanaSintetica from '../Basicos/PDFSemanaSintetica';
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js';

function CalendarioSemanaSintetica({
    ativar,
    subFuncionalidade: {
        url,
        tipoatividade,
        descricao
    },
}) {
    //Alimenta as opções
    const [periodoLetivoOptions, setPeriodoLetivoOptions] = useState();
    const [semestreOptions, setSemestreOptions] = useState();
    const [temasOptions, setTemasOptions] = useState();


    const [valorPeriodoLetivo, setValorPeriodoLetivo] = useState();
    const [semestreId, setSemestreId] = useState();

    const [horarioModal, setHorarioModal] = useState({});
    const [modalVisualizarEstaAberto, setModalVisualizarEstaAberto] = useState(false);
    const [semana, setSemana] = useState([]);
    const [periodoLetivo, setPeriodoletivo] = useState({});
    const [temas, setTemas] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [aulas, setAulas] = useState([]);
    const [turma, setTurma] = useState([]);
    const [horariosDefinidos, setHorariosDefinidos] = useState([]);
    const [datasInstitucionais, setDatasInstitucionais] = useState([]);
    const [infoHorarioSelecionado, setInfoHorarioSelecionado] = useState({});
    const [tiposDeAtividades, setTiposDeAtividades] = useState([]);
    const [horariosOfertas, setHorariosOfertas] = useState([]);
    const [ofertasPeriodos, setOfertasPeriodos] = useState({});
    const [locais, setLocais] = useState([]);
    const [modalConteudo, setModalConteudo] = useState(false);
    const [provaId, setProvaId] = useState();
    const [valorTema, setValorTema] = useState();
    const [pdfPronto, setPdfPronto] = useState(false); //arranjo tecnico para nao atualizar calendario do PDF
    const [aulasPDF, setAulasPDF] = useState([]);
    const [semanasPDF, setSemanasPDF] = useState([]);
    const [mesesPDF, setMesesPDF] = useState([]);
    const [exibirCalendario, setExibirCalendario] = useState(false);
    const [dataUltimaAtualizacao, setDataUltimaAtualizacao] = useState();
    const [turmasOptions, setTurmasOptions] = useState();
    const [valorTurma, setValorTurma] = useState();

    const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ];

    const getHorariosDoCurso = () =>
        temas && temas.curso && temas.curso.horarios
            ? temas.curso.horarios
            : [];

    const tiposDeAtividadesDoTema = () => {
        return tiposDeAtividades;
    }

    const loadTemasOptions = async (semestresIds) => {
        const response = await axiosApi.get(`temas-semestre?semestresIds[]=${semestresIds}`);
        setTemasOptions(response.data.dados.map(element => ({ label: element.nome, value: element.id })))
    }

    const loadTurmasOptions = async () => {
        const response = await axiosApi.get(`filtro-turmas-semestre`);
        setTurmasOptions(response.data.dados.map(element => ({ label: element.descricao, value: element.id })))
    }

    const loadInfosAnalitico = async () => {
        const response = await axiosApi.get(`semana-padrao/semana-sintetica`);
        gerarSelectOptions(response.data.filtros.periodos_letivos, response.data.filtros.semestres);
    }

    const gerarSelectOptions = (periodos_letivos, semestres) => {
        setPeriodoLetivoOptions(periodos_letivos && periodos_letivos.map(element => ({ label: element.descricao, value: element.id })));
        setSemestreOptions(semestres && semestres.map(element => ({ label: element.descricao, value: element.id })));
        setValorTema({ value: 'selecione', label: 'Selecione' });
    }

    useEffect(() => {
        loadInfosAnalitico();
    }, []);

    const pegaDataSelecionada = infoHorario =>
        dataDeUmaSemanaEmString({ semana, diaDaSemana: parseInt(infoHorario.dia_da_semana) });

    const selecionarHorario = infoHorario => {
        const horario_curso = getHorariosDoCurso().filter(horario => horario.id === infoHorario.horario_do_curso_id)[0];

        if (infoHorario.data_selecionada)
            setInfoHorarioSelecionado({
                ...infoHorario,
                dia_da_semana: diaDaSemanaDaDataString(infoHorario.data_selecionada),
                horario_curso,
            });
        else
            setInfoHorarioSelecionado({
                ...infoHorario,
                data_selecionada: pegaDataSelecionada(infoHorario),
                horario_curso,
            });
    }

    const onChangeSelect = (nome, valor) => {
        switch (nome) {
            case 'periodo_letivo_id':
                setValorTema({ value: 'selecione', label: 'Selecione' });
                let periodoLetivo = periodoLetivoOptions.find((element) => element.value === valor);
                setValorPeriodoLetivo(periodoLetivo)
                break;
            case 'semestre_id':
                let semestre = semestreOptions.find((element) => element.value === valor);
                setValorTema({ value: 'selecione', label: 'Selecione' });
                loadTemasOptions(semestre?.value);
                setSemestreId(semestre);
                loadTurmasOptions(semestre?.value);
                break;
            case 'tema_id':
                let tema = temasOptions.find((element) => element.value === valor);
                setValorTema(tema)
                break;
            case 'turma_id':
                let turma = turmasOptions.find((element) => element.value === valor);
                setValorTurma(turma)
            default:
                break;
        }
    }

    const abrirModalConteudo = (provaId) => {
        setModalConteudo(true);
        setProvaId(provaId);
    }

    const limparFiltro = () => {
        setExibirCalendario(false);
        setValorPeriodoLetivo(null);
        setSemestreId(null);
        setTemas([]);
        setTurma(null);
        setAulas([]);
        setLocais([]);
        setGrupos([]);
        setPeriodoletivo(null);
        setOfertasPeriodos([]);
        setHorariosOfertas([]);
        setTiposDeAtividades([]);
        setValorTema({ value: 'selecione', label: 'Selecione' });
    }

    useEffect(() => {
        let horarios = [];
        let datas_institucionais = [];

        if (horariosOfertas.length > 0) {
            horarios = semana?.flatMap(diaDaSemana =>
                horariosOfertas.filter(horarioOferta =>
                    horarioOferta.data?.toString() === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
                )
            );

            datas_institucionais = semana?.flatMap(diaDaSemana =>
                ofertasPeriodos?.calendarioInstitucional?.filter(data_institucional =>
                    data_institucional.data === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
                )
            );

            setInfoHorarioSelecionado({});
            setDatasInstitucionais(datas_institucionais);
            setHorariosDefinidos(horarios);
        }
    }, [semana, horariosOfertas]);

    useEffect(() => {
        //AQUI SETA A SEMANA QUE EXIBIRÁ NO CALENDARIO
        const periodoLetivo = temas.semanapadrao
        setSemana(semanaInicialDoPeriodo({ periodoLetivo }))
    }, [periodoLetivo]);

    useEffect(() => {
        let horarios = [];
        let datas_institucionais = [];

        if (!pdfPronto && semana.length > 0 && horariosOfertas.length > 0) {
            let semanas = [];
            let semanaAtual = semana;
            let mesesDaSemana = [];
            let institucionais = [];
            //loop para alimentar o PDF
            while (true) {
                const proximaSemana = avancarSemana({ semana: semanaAtual, periodoLetivo: temas?.semanapadrao });

                let semanaAux = '';

                semanas.push(semanaAtual);

                //semanas disponiveis
                semanaAtual.map((dataString, indice) => {
                    const data = new Date(dataString);
                    let semanaArray = semanaAux.split(" ");
                    if (!(semanaArray.includes(meses[data.getMonth()])))
                        semanaAux = semanaAux == '' ? meses[data.getMonth()] : semanaAux + " " + meses[data.getMonth()]
                });

                //datas institucionais
                institucionais.push(semanaAtual.flatMap(diaDaSemana =>
                    ofertasPeriodos.calendarioInstitucional.filter(data_institucional =>
                        data_institucional.data === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
                    )
                ));

                mesesDaSemana.push(semanaAux);

                // Verifica se a próxima semana é igual a semana atual
                if (proximaSemana === semanaAtual) {
                    break; // Se forem iguais, sai do loop
                }

                semanaAtual = proximaSemana;
            }
            setMesesPDF(mesesDaSemana)

            let horarios2 = []

            for (let i = 0; i < semanas.length; i++) {
                const semanaAtual = semanas[i];

                const horariosSemana = semanaAtual.flatMap(diaDaSemana =>
                    horariosOfertas.filter(horarioOferta =>
                        horarioOferta.data.toString() === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
                    )
                );

                horarios2.push(horariosSemana); // Adiciona os horários desta semana ao array de semanas
            }

            setSemanasPDF(semanas);
            setAulasPDF(horarios2);
            // setDatasInstitucionaisPDF(institucionais);
            setPdfPronto(true);
        }

        horarios = semana.flatMap(diaDaSemana =>
            horariosOfertas.filter(horarioOferta =>
                horarioOferta.data.toString() === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
            )
        );

        datas_institucionais = semana.flatMap(diaDaSemana =>
            ofertasPeriodos.calendarioInstitucional.filter(data_institucional =>
                data_institucional.data === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
            )
        );

        setInfoHorarioSelecionado({});
        setDatasInstitucionais(datas_institucionais);
        setHorariosDefinidos(horarios);
    }, [semana, horariosOfertas]);

    const mudarSemana = (flag) => {
        if (flag === "avancar")
            setSemana(avancarSemana({ semana, periodoLetivo }));
        if (flag === "retroceder") {
            var semanaPadrao = {
                ...periodoLetivo,
                inicio: temas?.semanapadrao.inicio
            }
            setSemana(retrocederSemana({ semana, periodoLetivo: semanaPadrao }));
        }
    }

    const mudarMes = (flag) => {
        if (flag === "avancar")
            setSemana(avancarMes({ semana, periodoLetivo }));
        if (flag === "retroceder") {
            var semanaPadrao = {
                ...periodoLetivo,
                inicio: temas?.semanapadrao.inicio
            }
            setSemana(retrocederMes({ semana, periodoLetivo: semanaPadrao }));
        }
    }

    const abrirModalVisualizar = horario => {
        setHorarioModal(horario);
        setModalVisualizarEstaAberto(true);
    }

    const exportSemanaPadraoPDF = async () => {
        const element = document.createElement('div');
        const componentHTML = ReactDOMServer.renderToString(
            <PDFSemanaSintetica
                grupos={grupos}
                // abrirModalAssociar={abrirCriarModalAssociar}
                // abrirModalVisualizar={abrirModalVisualizar}
                aulas={aulas}
                className="h-100"
                colClassname="calendario-padrao--comDomingoESabado"
                horariosDefinidos={horariosDefinidos}
                datasInstitucionais={datasInstitucionais}
                // deletarHorario={confirmaChamaDeleta}
                // deletarHorarioModal={confirmaDeletarHorarioModal}
                inicioDaSemana={0}
                finalDaSemana={6}
                horarioSelecionado={infoHorarioSelecionado}
                horariosCurso={getHorariosDoCurso()}
                tipoAtividades={tiposDeAtividadesDoTema()}
                selecionarHorario={selecionarHorario}
                horariosOfertas={horariosOfertas}
                setHorariosOfertas={setHorariosOfertas}
                éTipoOferta={true}
                aulasPDF={aulasPDF}
                semanasPDF={semanasPDF}
                mesesPDF={mesesPDF}
                periodoLetivo={periodoLetivo}
                temas={temas}
                dataUltimaAtualizacao={dataUltimaAtualizacao}
                tipoOferta={{
                    mudarSemana,
                    mudarMes,
                    semana,
                }}
                url={url}
            />);
        element.innerHTML = componentHTML;

        const options = {
            filename: `Semana Padrão - ${temas?.nome} - ${periodoLetivo?.descricao}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: [297, 210], orientation: 'landscape', autoAddPage: false },
        };

        html2pdf()
            .set(options)
            .from(element)
            .outputPdf(pdf => {
                // Após a renderização do conteúdo, adiciona manualmente uma quebra de página
                pdf.addPage();
            })
            .save();
    };

    const filtrar = async () => {
        if (valorPeriodoLetivo == undefined)
            ativar(400, 'Selecione um período letivo!')
        else if (semestreId == undefined)
            ativar(400, 'Selecione um semestre!')
        else if (valorTema == undefined)
            ativar(400, 'Selecione um tema!')
        else {
            axiosApi({
                method: "GET",
                url: `semana-padrao/filtro-semana-sintetica?periodo_letivo_id=${valorPeriodoLetivo?.value}&semestre_id=${semestreId?.value}&tema_id=${valorTema?.value}&turma_id=${valorTurma?.value}`,
            }).then(resp => {
                const {
                    tema,
                    aulas,
                    local,
                    professores,
                    grupoalunos,
                    periodoLetivo,
                    ofertaPeriodo,
                    horariosofertas,
                    tiposDeAtividades,
                    calendarioInstitucional,
                    turma,
                    ultima_atualizacao
                } = resp.data;

                ofertaPeriodo.calendarioInstitucional = calendarioInstitucional;
                setTemas(tema);
                setTurma(turma);
                setAulas(aulas);
                setLocais(local);
                setGrupos(grupoalunos);
                setPeriodoletivo(periodoLetivo);
                setOfertasPeriodos(ofertaPeriodo);
                setHorariosOfertas(horariosofertas);
                setTiposDeAtividades(tiposDeAtividades);
                setExibirCalendario(true);
                setDataUltimaAtualizacao(ultima_atualizacao)
            })
        }
    }

    return (
        <Fragment>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex mb-3 header-legenda text-header">
                <h3><strong> {descricao.toUpperCase()} </strong></h3>
            </Col>
            <Col sm={12} md={12}>
                <Row className="pr-5 px-3">
                    <SelectForm
                        label="Período Letivo*"
                        name="periodo_letivo_id"
                        placeholder="Período Letivo"
                        options={periodoLetivoOptions}
                        value={valorPeriodoLetivo ?? ''}
                        onChange={onChangeSelect}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }
                            }}
                    />
                    <SelectForm
                        telaAgenda={true}
                        label="Semestre*"
                        name="semestre_id"
                        value={semestreId}
                        options={semestreOptions}
                        onChange={onChangeSelect}
                        placeholder="Semestre"
                        // isMulti
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4
                                }

                            }}
                    />
                    <SelectForm
                        label="Tema*"
                        name="tema_id"
                        placeholder="Tema"
                        options={temasOptions}
                        onChange={onChangeSelect}
                        readOnly={!valorTema}
                        value={valorTema ?? ''}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }

                            }}
                    />
                    <SelectForm
                        label="Turma*"
                        name="turma_id"
                        placeholder="Turma"
                        options={turmasOptions}
                        onChange={onChangeSelect}
                        value={valorTurma ?? ''}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }

                            }}
                    />
                    <Col className="btn-limpar-filtro-agenda">
                        <Button
                            className="mx-3 py-2 pesquisar-agenda"
                            onClick={filtrar}
                        >Pesquisar</Button>
                    </Col>
                    <Col className="btn-limpar-filtro-agenda">
                        <Button
                            className="mx-3 py-2 pesquisar-agenda"
                            onClick={limparFiltro}
                        >Limpar</Button>
                    </Col>
                </Row>
            </Col>

            <Col xs={13} sm={13} md={13} lg={13} xl={13} className={'pr-5 tabela-avaliacao-analitica'} style={{ paddingBottom: exibirCalendario ? '' : '70vh' }}>
                {
                    exibirCalendario &&
                    <>
                        <CardCalendarioPadrao
                            grupos={grupos}
                            abrirModalVisualizar={abrirModalVisualizar}
                            aulas={aulas}
                            turma={turma}
                            className="h-100"
                            colClassname="calendario-padrao--comDomingoESabado"
                            horariosDefinidos={horariosDefinidos}
                            datasInstitucionais={datasInstitucionais}
                            inicioDaSemana={0}
                            finalDaSemana={6}
                            horariosCurso={getHorariosDoCurso()}
                            tipoAtividades={tiposDeAtividadesDoTema()}
                            horariosOfertas={horariosOfertas}
                            setHorariosOfertas={setHorariosOfertas}
                            selecionarHorario={selecionarHorario}
                            éTipoOferta={true}
                            tipoOferta={{
                                mudarSemana,
                                mudarMes,
                                semana,
                            }}
                            url={url}
                            periodoLetivo={periodoLetivo}
                            semestreId={semestreId}
                            temas={temas}
                        />
                        <Button
                            className="float-rigth export-btnConsulta"
                            onClick={() => exportSemanaPadraoPDF()}
                            size="sm"
                        >
                            <AiFillFilePdf size="1.2rem" style={{ padding: '1px' }} />
                            Exportar PDF
                        </Button>
                        <ModalVisualizar
                            horarios_aulas={horarioModal?.horarios_aulas}
                            modalVisualizarEstaAberto={modalVisualizarEstaAberto}
                            setModalVisualizarEstaAberto={setModalVisualizarEstaAberto}
                            grupos={grupos}
                            tiposDeAtividades={tiposDeAtividades}
                            abrirModalConteudo={abrirModalConteudo}
                        />
                        <ModalConteudo
                            isOpen={modalConteudo}
                            id={provaId}
                            setModalConteudo={setModalConteudo}
                        />
                    </>
                }


            </Col>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        atualizarDadosRemotamente: (params) => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, params)),
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        exibirCalendario: state.exibirCalendario ?? false
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardHoc(CalendarioSemanaSintetica))